<template>
  <div>
    <div v-if="storeLoaded" class="hierarchy-details-container">
        <div class="input-display">
            <div class="left-input">
                <Input :label="$t(`name`)" :orientation="'v'" name="name" v-model="localHierarchyBasicDetailsName"  :isDisabled="!basicDetailsEditing" :hasError="nameError" :errorMessage="$t(nameErrorMessage)"/>
            </div>
            <div class="right-input">
                <Input :label="$t(`code`)" :orientation="'v'" name="code" v-model="localHierarchyBasicDetailsCode"  :isDisabled="!basicDetailsEditing"  :hasError="codeError" :errorMessage="$t(codeErrorMessage)"/>
            </div>
        </div>
        <div>
            <Checkbox name="monitoring-methods" :optionsWithKeyValue="availableMonitoringMethodsAsOptions" label=""  :isDisabled="!basicDetailsEditing" v-model="selectedTechOptions"/>
        </div>
        <div class="input-display">
          <div class="left-input apply-theme-color for-alignment">
            {{$t("note_edit_hierarchy")}}
          </div>
            <Button v-if="!basicDetailsEditing" :label="$t(`edit_details`)" :type="'outlined'" @click="editBasicDetails"/>
            <Button  v-if="basicDetailsEditing" :label="$t(`save_changes`)" :type="'outlined'" @click="saveBasicDetails" :disabled="!overallError"/>
            <div v-if="basicDetailsEditing" class="little-left-margin"><Button  :label="$t(`cancel`)" :type="'outlined'" @click="cancelUpdateChanges"/></div>
        </div>
        <div class="lower-section">
        <div class="input-display">
            <div class="left-input">
                <Input :label="$t(`hierarchy_id`)" :orientation="'v'" name="HierarchyId" v-model="localHierarchyBasicDetailsId" :isDisabled="true"/>
            </div>
            <div class="right-input">
                <Input :label="$t(`parent_hierarchy_id`)" :orientation="'v'" name="Parent Hierarchy Id" v-model="localHierarchyBasicDetailsParentId" :isDisabled="true"/>
            </div>
                <div class="left-input">
                <Input :label="$t(`level`)" :orientation="'v'" name="Level" v-model="localHierarchyBasicDetailsLevel" :isDisabled="true"/>
            </div>
            <div class="right-input">
                <Input :label="$t(`type`)" :orientation="'v'" name="type" v-model="localHierarchyBasicDetailsType" :isDisabled="true"/>
            </div>
            <div class="left-input">
                <Input :label="$t(`patients_linked_to_hierarchy`)"  :orientation="'v'" name="cases" v-model="localHierarchyBasicDetailsCases" :isDisabled="true"/>
            </div>
            <div class="right-input">
                <Input :label="$t(`children_hierarchies`)" :orientation="'v'" name="children" v-model="localHierarchyBasicDetailsChildren" :isDisabled="true"/>
            </div>
            <div class="left-input">
                <Input :label="$t(`sibling_hierarchies`)"  :orientation="'v'" name="siblings" v-model="localHierarchyBasicDetailsSiblings" :isDisabled="true"/>
            </div>
            <div class="right-input">
            </div>
        </div>
          <div class="button-align"><Button v-if="hierarchyBasicDetails.parentId" :label="$t(`add_hierarchy_at_this_level`)" :type="'outlined'" @click="addHierarchyAtThisLevel"/></div>
          <div class="button-align left-button-position"><Button :label="$t(`add_hierarchy_below_this`)" :type="'outlined'" @click="addHierarchyBelow"/></div>
        </div>
    </div>
    <div v-else class="hierarchy-details-container">
      <p class="apply-theme-color">{{$t("select_hierarchy_see_details")}}</p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Button from '@/app/shared/components/Button.vue'
import Checkbox from '@/app/shared/components/CheckboxGroup.vue'
import Input from '@/app/shared/components/Input.vue'
export default {
  name: 'hierachyDetails',
  data () {
    return {
      selectedTechOptions: [],
      localHierarchyBasicDetails: {},
      localHierarchyBasicDetailsName: '',
      localHierarchyBasicDetailsCode: '',
      localHierarchyBasicDetailsId: '',
      localHierarchyBasicDetailsParentId: '',
      localHierarchyBasicDetailsLevel: '',
      localHierarchyBasicDetailsType: '',
      localHierarchyBasicDetailsCases: '',
      localHierarchyBasicDetailsChildren: '',
      localHierarchyBasicDetailsSiblings: '',
      nameError: true,
      nameErrorMessage: '',
      codeError: true,
      codeErrorMessage: ''
    }
  },
  components: {
    Button,
    Checkbox,
    Input
  },
  mounted () {
    this.resetLocalBasicDetails()
  },
  methods: {
    ...mapActions('HiearachyManagement', ['updateBasicDetails', 'toggleBasicDetailsEdit']),
    editBasicDetails () {
      this.toggleBasicDetailsEdit()
    },
    cancelUpdateChanges () {
      this.resetLocalBasicDetails()
      this.toggleBasicDetailsEdit()
      this.$emit('resetbasicdetails')
    },
    saveBasicDetails () {
      this.updateBasicDetails({ data: { hierarchyId: this.hierarchyBasicDetails.id, name: this.localHierarchyBasicDetailsName, code: this.localHierarchyBasicDetailsCode, monitoringMethods: this.selectedTechOptions } })
    },
    resetLocalBasicDetails () {
      this.selectedTechOptions = [...this.selectedMonitoringMethods]
      if (this.hierarchyBasicDetails && Object.keys(this.hierarchyBasicDetails)?.length) {
        this.localHierarchyBasicDetailsName = this.hierarchyBasicDetails.name?.toString()
        this.localHierarchyBasicDetailsCode = this.hierarchyBasicDetails.code?.toString()
        this.localHierarchyBasicDetailsId = this.hierarchyBasicDetails.id?.toString()
        this.localHierarchyBasicDetailsParentId = this.hierarchyBasicDetails.parentId?.toString()
        this.localHierarchyBasicDetailsLevel = this.hierarchyBasicDetails.level?.toString()
        this.localHierarchyBasicDetailsType = this.hierarchyBasicDetails.type?.toString()
        this.localHierarchyBasicDetailsCases = this.hierarchyBasicDetails.cases?.toString()
        this.localHierarchyBasicDetailsChildren = this.hierarchyBasicDetails.children?.toString()
        this.localHierarchyBasicDetailsSiblings = this.hierarchyBasicDetails.siblings?.toString()
      }
    },
    addHierarchyBelow () {
      this.$parent.$emit('addhierarchy', { id: this.hierarchyBasicDetails.id, fetchAtParentLevel: false })
    },
    addHierarchyAtThisLevel () {
      this.$parent.$emit('addhierarchy', { id: this.hierarchyBasicDetails.parentId, fetchAtParentLevel: true })
    }
  },
  computed: {
    ...mapState('HiearachyManagement', [
      'hierarchyDetails',
      'hierarchyBasicDetails',
      'availableMonitoringMethodsAsOptions',
      'selectedMonitoringMethods',
      'basicDetailsEditing'
    ]),
    storeLoaded () {
      return this.hierarchyBasicDetails !== null
    },
    overallError () {
      return !this.codeError && !this.nameError
    }
  },
  watch: {
    hierarchyBasicDetails (newValue, oldValue) {
      if (newValue !== undefined && newValue !== null) {
        this.resetLocalBasicDetails()
      }
    },
    localHierarchyBasicDetailsName () {
      if (this.localHierarchyBasicDetailsName.length === 0) {
        this.nameError = true
        this.nameErrorMessage = 'enter_vaild_name'
      } else if (this.localHierarchyBasicDetailsName.length > 255) {
        this.nameError = true
        this.nameErrorMessage = 'name_to_be_less_than'
      } else {
        this.nameError = false
      }
    },
    localHierarchyBasicDetailsCode () {
      if (this.localHierarchyBasicDetailsCode.length === 0) {
        this.codeError = true
        this.codeErrorMessage = 'enter_vaild_code'
      } else if (this.localHierarchyBasicDetailsCode.length > 10 || this.localHierarchyBasicDetailsCode !== this.localHierarchyBasicDetailsCode.toUpperCase()) {
        this.codeError = true
        this.codeErrorMessage = 'hierarchy_code_validation_msg'
      } else {
        this.codeError = false
      }
    }
  }
}
</script>
<style scoped>

.hierarchy-details-container {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2%;
}
.input-display {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.left-input {
  flex: 1 0 40%;
  padding-right: 5%;
}
.right-input {
  flex: 1 0 40%;
  padding-left: 5%;
}
.additional-left-margin {
  padding-left: 5%
}
.more-left-margin {
  margin-left: 10%
}
.little-left-margin {
  padding-left: 1%
}
.little-margin-top {
  padding-top: 3%
}
.lower-section {
  border-width: thin;
  border-top-style: solid;
  border-color: inherit;
  margin-top: 5%;
  padding-top: 4%;
}
.apply-theme-color {
  color:var(--text-primary-color);
}
.for-alignment {
  margin-top: auto;
  margin-bottom: auto;
}
.button-align {
  margin-left: auto;
  margin-right: 0;
  display: inline;
  position: relative;
  float: right;
}
.left-button-position {
  right: 1%;
}
</style>
